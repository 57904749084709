/*
 * Common styles
 */


@mixin common($param: '') {



	/*===================================
	=            Page states            =
	===================================*/



	body.body--loading{
		//background-color: $color-dark;
	}



	.revealer{
		position: absolute;
		top: 0%;
		left: 0%;
		right: 0;
		bottom: 0;
		z-index: 10;
		background-color: $color-dark;
	}


	
	/*=====  End of Page states  ======*/
	




	/*============================================
	=            General page styling            =
	============================================*/
	


	.page{
		min-height: 100vh;
	}
	

	
	/*=====  End of General page styling  ======*/





	/*==================================================
	=            Vertical centering helpers            =
	==================================================*/



	.va-parent{
		@include flexbox;
		@include justify-content(center);
	}


	.va-parent--fullheight{
		height: 100vh;
	}

	


	.va{
		@include align-self(center);
	}
	
	
	
	/*=====  End of Vertical centering helpers  ======*/
	
	

}



@mixin common-tablet($param: '') { 

}



@mixin common-desktop($param: '') {

}



@mixin common-hd($param: '') {

}

