// ==========================================================================
// Base – Forms
// ==========================================================================

// Form, Fieldset and Legend
fieldset,
legend {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  @include font-size($base-font-size + 2);
  @include line-height($base-line-height + 2);
  font-weight: $font-bold;
}
.form {
  padding-top: 1em;
  padding-bottom: 1em;
}

// All Form Elements
label,
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

// Inputs
input { line-height: normal; }
.input { width: 100%; }
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"] {
  height: 36px;
  padding: 0 .4em;
}
input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Text Area
textarea { overflow: auto; }

// Select Boxes
select { text-transform: none; }
optgroup { font-weight: $font-bold; }

// Buttons
button {
  overflow: visible;
  text-transform: none;
}
button, html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled], html input[disabled] { cursor: default; }
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Placeholder Colour
::-webkit-input-placeholder { color: $base-placeholder-color; }
:-moz-placeholder { color: $base-placeholder-color; }
::-moz-placeholder { color: $base-placeholder-color; }
:-ms-input-placeholder { color: $base-placeholder-color; }