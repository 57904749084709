// ==========================================================================
// Base – Variables
// ==========================================================================

// Colors

$color-green:									#1DE4AE;
$color-dark:									#070E19;
$color-darkblue: 								#09162B;
$color-white:									#ffffff;
$color-black:									#000000;


// Global Typography
$base-background-color:                         #fff;
$base-font-family:                              'Roboto', sans-serif;
$base-font-size:                                16;
$base-line-height:                              30;
$base-font-weight:                              400;
$base-font-color:                               #070E19;
$base-link-color:                               #070E19;

// Font Weights
$font-thin:                                     100;
$font-light:                                    300;
$font-regular:                                  400;
$font-medium:                                   500;
$font-semi-bold:                                600;
$font-bold:                                     700;
$font-extra-bold:                               800;
$font-ultra-bold:                               900;

// Headings
$heading-font-family:                           'Playfair Display', serif;;
$h1-font-size:                                  32;
$h1-line-height:                                38;
$h1-font-weight:                                $font-bold;
$h1-color:                                      #070E19;
$h2-font-size:                                  26;
$h2-line-height:                                32;
$h2-font-weight:                                $font-bold;
$h2-color:                                      #070E19;
$h3-font-size:                                  22;
$h3-line-height:                                28;
$h3-font-weight:                                $font-regular;
$h3-color:                                      #070E19;
$h4-font-size:                                  18;
$h4-line-height:                                24;
$h4-font-weight:                                $font-regular;
$h4-color:                                      #070E19;
$h5-font-size:                                  16;
$h5-line-height:                                22;
$h5-font-weight:                                $font-regular;
$h5-color:                                      #070E19;
$h6-font-size:                                  14;
$h6-line-height:                                20;
$h6-font-weight:                                $font-regular;
$h6-color:                                      #070E19;

// Blockquotes
$base-blockquote-font-family:                   sans-serif;
$base-blockquote-font-size:                     22;
$base-blockquote-line-height:                   28;
$base-blockquote-color:                         #424242;
$base-blockquote-cite-color:                    #777;

// Code
$base-code-font-family:                         'courier new', monospace, serif;
$base-code-font-size:                           13;
$base-code-line-height:                         18;
$base-code-color:                               #272727;
$base-code-background-color:                    #f1f1f1;
$base-code-border-color:                        #d7d7d7;

// Forms
$base-placeholder-color:                        #999;

// Grid Containers
$mobile-container:                              100%;
$tablet-container:                              720px;
$desktop-container:                             960px;
$hd-container:                                  1200px;

// Responsive Breakpoints
$tablet-breakpoint:                             ($tablet-container + 20);
$desktop-breakpoint:                            ($desktop-container + 20);
$hd-breakpoint:                                 ($hd-container + 20);

// Spacing
$spacer:                     					1rem !default;
$spacer-x:                   					$spacer !default;
$spacer-y:                   					$spacer !default;


// Z-index
$z-nav:											500;
$z-foreground:									200;
$z-background: 									1;
