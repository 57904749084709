// ==========================================================================
// Base – Mixins
// ==========================================================================
// Font sizes (primary sizing in `rem` units with a fallback of `px`)
// Example usage .font-size(18);
@mixin font-size ($size) {
  $remValue: $size / 16;
  $pxValue: ($size);
  font-size: $pxValue + px; 
  font-size: $remValue + rem;
}

// Line height sizes (primary sizing in `rem` units with a fallback of `px`)
// Example usage .line-height(22);
@mixin line-height ($size) {
  $remValue: $size / 16;
  $pxValue: ($size);
  line-height: $pxValue + px; 
  line-height: $remValue + rem;
}

// Border Radius
// Example usage .border-radius(2px) || .border-radius(100%);
@mixin rounded($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  border-top-left-radius: $topleft;
}

// Rotate (Transformation)
// Example usage .rotate(180) || .rotate(-45);
@mixin rotate($rotate) {
  -ms-transform: rotate($rotate + deg);
  -webkit-transform: rotate($rotate + deg);
  transform: rotate($rotate + deg);
}

// SVG background with PNG fallback
// Example Usage .svg-bg("img/banner.svg", "img/banner.png", 50%, 50%, no-repeat);
@mixin bg-svg($svg, $png, $x: 0, $y: 0, $repeat: no-repeat) {
  background: url($svg) $x $y $repeat;
  .no-svg & {
    background: url($png) $x $y $repeat;
  }
}


// Background Color with Opacity
// Example Usage: .bg-rgba(#111, 50%);
@mixin bg-rgba($color, $alpha) {
  $opacity: $alpha / 100%;
  $rgba: rgba($color, $opacity);
  background: $color;
  background: rgba($color, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$rgba}, endColorstr=#{$rgba});
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$rgba}, endColorstr=#{$rgba});
}

// Opacity
// Example Usage: .opacity(50%);
@mixin opacity($percentOpacity: 50%) {
  $opacity: $percentOpacity / 100%;
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

// Animations
// Example Usage .animation(1s);
@mixin animate($duration: 1s) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

// Animation Delay
// Example Usage .animation-delay(1s)
@mixin animate-delay($delay: 1s) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

// CSS Transitions
// Example Usage .transition(0.3s)
@mixin transition($duration:0.2s, $prop:all, $ease:ease-out) {
  -webkit-transition: $prop $duration $ease;
  -moz-transition: $prop $duration $ease;
  -o-transition: $prop $duration $ease;
  transition: $prop $duration $ease;
}

// Transition Delay
// Example usage .transition-delay(.3s)
@mixin transition-delay($speed: 0.3s) {
  -webkit-transition-delay: $speed;
  -moz-transition-delay: $speed;
  transition-delay: $speed;
}

// CSS Transform
@mixin transform($arguments...) {
  -webkit-transform: $arguments;
  -moz-transform: $arguments;
  -o-transform: $arguments;
  -ms-transform: $arguments;
  transform: $arguments;
}

// Scale transform
@mixin scale($ratio){
  @include transform(scale($ratio));
}

// Sizing shortcuts

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

// User select
@mixin user-select($argument: none) {
  -webkit-user-select: $argument;
  -moz-user-select: $argument;
  -ms-user-select: $argument;
  user-select: $argument;
}

@mixin retina-image($filename, $background-size, $extension: 'png', $retina-filename: null, $retina-suffix: '@2x') {
 
  background-image: url("#{$filename}.#{$extension}");
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 1.5/1),
  only screen and (min-resolution: 144dpi),
  only screen and (min-resolution: 1.5dppx) { 
    @if $retina-filename {
      background-image: url("#{$retina-filename}.#{$extension}");
    } @else {
      background-image: url("#{$filename}#{$retina-suffix}.#{$extension}");
    }
      
    background-size: $background-size;
  }
}

@mixin transform-origin($x:50%,$y:50%){
  -ms-transform-origin: $x $y; /* IE 9 */
  -webkit-transform-origin: $x $y; /* Chrome, Safari, Opera */
  transform-origin: $x $y;
}


/// Horizontal, vertical or absolute centering
/// If specified, this mixin will use negative margins
/// based on element's dimensions. Else, it will rely
/// on CSS transforms which have a lesser browser support
/// but are more flexible as they don't require to set
/// any specific dimensions to the element.
/// 
/// @param {Length | null} $width [null] - Element width
/// @param {Length | null} $height [null] - Element height
///
@mixin center($width: null, $height: null) {
  position: absolute;
  top: 50%;
  left: 50%;
  
  @if not $width and not $height {
    @include transform(translate(-50%, -50%));
  } @else if $width and $height {
    width: $width;
    height: $height;
    margin: -($width / 2) #{0 0} -($height / 2)
  } @else if not $height {
    margin-left: -($width / 2);
    @include transform(translateY(-50%));
    width: $width;
  } @else {
    margin-top: -($height / 2);
    @include transform(translateX(-50%));
    height: $height;
  }
}