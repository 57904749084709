/**
 *
 * Homepage styling
 *
 */

@mixin home($param: '') {


	/*====================================================================
	=            Homepage block with logo, title and subtitle            =
	====================================================================*/





	.page--home{
		::selection{
			background-color: rgba(9,22,43,.2);
			//color: $color-white;
		}
	}





	.home__body{
		text-align: center;
	}





	.home__title{
		width: 70vw;
		margin-bottom: 1.2vw;
	}





	.home__subtitle{
		font-size: 4vw;
		line-height: 2vw;
		font-weight: $font-regular;
	}





	.home__title, .home__subtitle{
		position: relative;
		z-index: 2;
	}





	.home__logo{

		width: 45vw;
		@include center;
		z-index: 1;
		

		svg{
			width: 100%;
			height: auto;
			-webkit-transform: translate3d(0,0,0);
    		transform: translate3d(0,0,0);
		}

	}




	.home__contact{


		position: fixed;
		left: 50%;
		bottom: 10vh;
		text-decoration: none;
		text-transform: uppercase;
		font-size: .6rem;
		letter-spacing: .4rem;
		width: 80%;
		z-index: 10;
		margin-left: -40%;




	} 


	@media only screen and (min-device-width: 480px) and (orientation: landscape){

		.home__logo{

			width: 40vw;
		}

		.home__title{
			width: 60vw;
		}

		.home__subtitle{
			font-size: 3.5vw;
			line-height: 2vw;
		}

	}



	
	
	
	/*=====  End of Homepage block with logo, title and subtitle  ======*/
	
		
}



@mixin home-tablet($param: '') {

	.home__contact{

		right: 30px;
		top: 30px;
		left: auto;
		bottom: auto;
		width: auto;
		margin-left: 0;
		@include transform(translateX(0));

	}
	

}



@mixin home-desktop($param: '') {





	.home__title{
		width: 50vw;
		margin-bottom: 1vw;
	}





	.home__subtitle{
		font-size: 2vw;
		line-height: 2vw;
	}





	.home__logo{
		width: 25vw;
	}


	
}



@mixin home-hd($param: '') {

}

